// import Energy from "./images/energy.png";
import SGCasino from "./images/sgcasino.png";
import Wazamba from "./images/wazamba2.png";
import Slotbox from "./images/slotbox.png";
import Ursa from "./images/ursa.jpg";
import Legiano from "./images/legiano.png";
import Cazimbo from "./images/cazimbo.png";
import Spinrollz from "./images/spinrollz.png";
import Gomblingo from "./images/gomblingo.png";
import Vauhdikas from "./images/vauhdikas.png";
import Casimba from "./images/casimba.png";
import Pelikaani from "./images/pelikaani.png";
import Wildz from "./images/wildz.png";
import Chipz from "./images/chipz.png";
import Flappy from "./images/flappy.png";
import Posido from "./images/posido.png";
import None from "./images/n1casino.png";
import Chalk from "./images/chalk.png";
import Campeon from "./images/campeon.png";
import Rtbet from "./images/rtbet.png";
import Pelataan from "./images/pelataan.png";
import Dreamvegas from "./images/dream.png";
import Huikee from "./images/huikee.png";
import Sci from "./images/scibet.png";
import Lumo from "./images/lumo.webp";

const bonuses = [
  // {
  //   name: "energy",
  //   type: "bonus",
  //   img: Energy,
  //   link: "https://charity.energy.partners/redirect.aspx?pid=50091&lpid=1210&bid=5799",
  //   payNplay: true,
  //   highlight: true,
  //   license: "MGA",
  //   perks: {
  //     bonus: {
  //       maxSum: 300,
  //       percentage: 100,
  //     },
  //     freeSpins: {
  //       value: 300,
  //     },
  //     wager: { value: 30 },
  //   },
  // },
  {
    name: "lumo",
    type: "bonus",
    img: Lumo,
    link: "https://media1.casimbaaff.com/redirect.aspx?pid=47055&lpid=93&bid=1499",
    license: "MGA",
    payNplay: true,
    highlight: true,
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 150,
      },
      wager: {
        value: 40,
      },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "sci",
    type: "bonus",
    img: Sci,
    link: "https://go.campeonaffiliatesdirect.com/visit/?bta=37130&nci=8541",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: {
        value: 35,
      },
      freeSpins: {
        value: 500,
      },
    },
  },
  {
    name: "chalk",
    type: "bonus",
    img: Chalk,
    link: "https://go.campeonaffiliatesdirect.com/visit/?bta=37130&nci=8199",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 1500,
        percentage: 140,
      },
      wager: {
        value: 50,
        condition: "30e min. dep.",
      },
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "posido",
    type: "bonus",
    img: Posido,
    link: "https://conversionvault10550240.o18.link/c?o=21346380&m=22338&a=625440",
    license: "Estonia",
    nonSticky: true,
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: {
        value: 35,
      },
    },
  },
  {
    name: "pelataan",
    type: "bonus",
    img: Pelataan,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_36183b_872c_&affid=6969&siteid=36183&adid=872&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 150,
      },
      wager: {
        value: 40,
      },
      freeSpins: {
        value: 100,
      },
    },
  },
  {
    name: "n1",
    type: "bonus",
    img: None,
    link: "http://url1009.softswiss-service.com/ls/click?upn=u001.Ygo2sEeJ8-2Fx-2BG91Z6c7jCE1ZER3N-2Fx1y7KoBAZnKUcDleS-2FuRmOvCHgX5YcwLjiHUyRg_-2FZ2voozZI-2FoRtG4SqHh5mTv3j02offQC97bPOAIGehwBhM7lgW2YZVD8lk2CdUJaTdONAb0CSNmOiTMFTg6HoHeh5ggEANBs-2FmWIKhs59lqTDUCPjBosSUwCCtF1Or-2Fy4BbTSdQki5mNDUWsIxlPb0dQD8Q6z86dSmHaPxwcaEh811-2FZJKCrFggom-2FCBy9IVrzg4TtQ2-2BwZUu1ds0Sw86rJIeeKAivF7cQBeteFSTzM-3D",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 1000,
        percentage: 100,
      },
      freeSpins: {
        value: 150,
      },
      wager: {
        value: 50,
      },
    },
  },
  {
    name: "campeon",
    type: "bonus",
    img: Campeon,
    link: "https://go.campeonaffiliatesdirect.com/visit/?bta=37130&nci=7606",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: {
        value: 40,
      },
    },
  },
  {
    name: "rtbet",
    type: "bonus",
    img: Rtbet,
    link: "https://conversionvault10550240.o18.link/c?o=21357057&m=22338&a=625443",
    license: "Pagcor",
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: {
        value: 35,
      },
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "huikee",
    type: "bonus",
    img: Huikee,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_36183b_869c_&affid=6969&siteid=36183&adid=869&c=",
    perks: {
      bonus: { maxSum: 400, percentage: 100 },
      freeSpins: { value: 50 },
      wager: { value: 40 },
    },
    payNplay: true,
    license: "MGA",
  },
  {
    name: "dream",
    type: "bonus",
    img: Dreamvegas,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_36915b_876c_&affid=6452&siteid=36915&adid=876&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 2500,
        percentage: 100,
      },
      wager: {
        value: 35,
      },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "vauhdikas",
    type: "bonus",
    img: Vauhdikas,
    link: "https://afftrackcf.21.partners/C.ashx?btag=a_18754b_1099c_&affid=4426&siteid=18754&adid=1099&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      freeSpins: {
        value: 100,
      },
      wager: {
        value: 40,
        condition: "CODE: VAUH",
      },
    },
  },
  {
    name: "slotbox",
    type: "bonus",
    img: Slotbox,
    link: "https://bonus.slotbox.com/fi-parniands-1/",
    license: "Estonia",
    perks: {
      bonus: {
        maxSum: 100,
        percentage: 100,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "casibma",
    type: "bonus",
    img: Casimba,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_36183b_861c_&affid=6969&siteid=36183&adid=861&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 5000,
        percentage: 100,
      },
      freeSpins: {
        value: 50,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "pelikaani",
    type: "bonus",
    img: Pelikaani,
    link: "https://go.moonrocketaffiliates.com/visit/?bta=35509&brand=pelikaani&utm_campaign=parniands",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 1,
        condition: "Superspin",
      },
      wager: {
        value: 1,
      },
    },
  },
  {
    name: "wildz",
    type: "bonus",
    img: Wildz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903431&nci=5988",
    payNplay: true,
    nonSticky: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 35 },
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "flappy",
    type: "bonus",
    img: Flappy,
    link: "https://record.winmaniacs.com/_W3_qG1AdETBD3TEmsPWI0WNd7ZgqdRLk/1/",
    nonSticky: true,
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 50 },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "chipz",
    type: "bonus",
    img: Chipz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903431&nci=5931",
    payNplay: true,
    license: "MGA",
    perks: {
      wager: { value: 0 },
      freeSpins: {
        value: 100,
      },
    },
  },
  {
    name: "ursa",
    type: "bonus",
    img: Ursa,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_35581b_853c_&affid=6969&siteid=35581&adid=853&c=",
    license: "Isle of Man",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
      freeSpins: {
        value: 50,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "legiano",
    type: "bonus",
    img: Legiano,
    link: "https://lgno.servclick1move.com/?mid=226818_1350737",
    license: "PAGCOR",
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "cazimbo",
    type: "bonus",
    img: Cazimbo,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5395&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "spinrollz",
    type: "bonus",
    img: Spinrollz,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5540&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "gomblingo",
    type: "bonus",
    img: Gomblingo,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5477&afp=",
    payNplay: true,
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 150,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40, condition: "Code: GOBLIONGOLD200" },
    },
  },
  {
    name: "sgcasino",
    type: "bonus",
    img: SGCasino,
    link: "https://sgc.servclick1move.com/?mid=226818_1350741",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Curacao",
    payNplay: true,
  },
  {
    name: "wazamba",
    type: "bonus",
    img: Wazamba,
    link: "https://wzbw.servclick1move.com/?mid=226818_1350742",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Estonia",
    payNplay: true,
  },
];

export default bonuses;
